const html = document.querySelector("html");

if (html) {
  const changeFontsSize = () => {
    if (window.innerWidth < 1920) {
      html.style.fontSize = "62.5%";
    } else if (window.innerWidth >= 3840) {
      html.style.fontSize = "100%";
    } else if (window.innerWidth > 1920) {
      const step = 0.02953125;
      const fullHd = 1920;
      let percentages;
      percentages = (window.innerWidth - fullHd) * step + 62.5;
      html.style.fontSize = `${percentages}%`;
    }
  };
  window.addEventListener("resize", changeFontsSize);
  changeFontsSize();
}

const scrollToTop = document.querySelector(".scroll-to-top");

if (scrollToTop) {
  scrollToTop.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
}

const menuItems = document.querySelectorAll(
  ".side-navigation .menu-item-has-children"
);

if (menuItems) {
  const toggleMenu = () => {
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].classList.remove("active");
      const link = menuItems[i].querySelector("a");
    }
  };
  for (let i = 0; i < menuItems.length; i++) {
    menuItems[i].addEventListener("click", () => {
      toggleMenu();
      setTimeout(() => {
        menuItems[i].classList.add("active");
      }, 400);
    });
  }
}

const hamburger = document.querySelector(".hamburger-btn");
const heroContent = document.querySelector(".page-hero__content-wrapper");
const heroMenu = document.querySelector(".page-hero__menu");
const centerMenu = document.querySelector(".site-header__center ");

if (hamburger) {
  const sideMenu = document.querySelector(".side-menu");
  const body = document.querySelector("body");
  hamburger.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    hamburger.classList.toggle("active");
    sideMenu.classList.toggle("active");
    if (sideMenu.classList.contains("active")) {
      body.style.overflow = "hidden";
      heroContent.style.opacity = "0";
      heroMenu.style.opacity = "0";
      centerMenu.style.opacity = "0";
    } else {
      body.style.overflow = "auto";
      heroContent.style.opacity = "1";
      heroMenu.style.opacity = "1";
      centerMenu.style.opacity = "1";
      for (let i = 0; i < menuItems.length; i++) {
        menuItems[i].classList.remove("active");
      }
    }
  });
}
const customSelect = document.querySelector(
  ".site-header__right .language-switcher"
);
const selectBtn = document.querySelector(".site-header__right .select-button");

selectBtn.addEventListener("click", () => {
  customSelect.classList.toggle("active");
  selectBtn.setAttribute(
    "aria-expanded",
    selectBtn.getAttribute("aria-expanded") === "true" ? "false" : "true"
  );
});

const selectedValue = document.querySelector(
  ".site-header__right .selected-value"
);
const optionsList = document.querySelectorAll(
  ".site-header__right .select-dropdown li"
);

optionsList.forEach((option) => {
  function handler(e) {
    if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
      selectedValue.textContent = this.children[1].textContent;
      customSelect.classList.remove("active");
    }
    if (e.key === "Enter") {
      selectedValue.textContent = this.textContent;
      customSelect.classList.remove("active");
    }
  }

  option.addEventListener("keyup", handler);
  option.addEventListener("click", handler);
});

const customSelect2 = document.querySelector(".side-menu .language-switcher");
const selectBtn2 = document.querySelector(".side-menu .select-button");

selectBtn2.addEventListener("click", () => {
  customSelect2.classList.toggle("active");
  selectBtn2.setAttribute(
    "aria-expanded",
    selectBtn2.getAttribute("aria-expanded") === "true" ? "false" : "true"
  );
});

const selectedValue2 = document.querySelector(".side-menu .selected-value");
const optionsList2 = document.querySelectorAll(
  ".side-menu .select-dropdown li"
);

optionsList2.forEach((option) => {
  function handler(e) {
    if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
      selectedValue2.textContent = this.children[1].textContent;
      customSelect2.classList.remove("active");
    }
    if (e.key === "Enter") {
      selectedValue2.textContent = this.textContent;
      customSelect2.classList.remove("active");
    }
  }

  option.addEventListener("keyup", handler);
  option.addEventListener("click", handler);
});
